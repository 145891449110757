import {createContext, useEffect, useState} from "react";
import {apiUrl, headers} from "../helpers";
import axios from "axios";

export const UserProfileContext = createContext({})

export const UserProfileContextProvider = ({children}) => {
    const [userProfile,setUserProfile] = useState({})

    useEffect(()=>{
        axios.get(`${apiUrl}/self`,{
            withCredentials:true,
            headers:headers
        }).then(res => {
            if (res.data.user_type !== 'rep'){
                //log the user out
                axios.post(`${apiUrl}/logout`,{},{
                    headers:headers,
                    withCredentials:true
                }).then(res => {
                    if (res.data.status === 'success'){
                        window.location.replace('/not-authorised')
                    }
                }).catch(err => {
                    console.log(err)
                })
            }else{
                setUserProfile(res.data)
            }
        }).catch(err => {
            if (window.location.pathname !== '/' && !window.location.pathname.includes('not-authorised')&& !window.location.pathname.includes('activate') && err.response.status === 401){
                window.location.replace('/')
            }else{
                setUserProfile(err.response.data)
            }
        })
    },[])

    return <UserProfileContext.Provider value={userProfile}>
            {children}
    </UserProfileContext.Provider>
}

export default UserProfileContextProvider