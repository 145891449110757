interface StatusMessageProps {
    message:string,
    status:'success' | 'fail'
}
const StatusMessage = (props:StatusMessageProps) => {
    const {message,status} = props
    return (
        <div className={`${status === 'fail' ? 'bg-red/30 text-red' : 'bg-primary-green/30 text-primary-green'}mb-[16px] py-[4px] px-[8px] rounded-[8px] text-center`}>
            {message}
        </div>
    )
}

export default StatusMessage