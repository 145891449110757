import DashboardLayout from "../components/DashboardLayout";
import TextField from "../components/TextField";
import {useState} from "react";
import Button from "../components/Button";
import Heading4 from "../components/Heading4";
import StatusMessage from "../components/StatusMessage";
import {apiUrl, headers, logout} from "../helpers";
import axios from "axios";
import LoadingSpinner from "../components/LoadingSpinner";

const ChangePassword = () => {
    const [currPassword,setCurrPassword] = useState('')
    const [newPassword,setNewPassword] = useState('')
    const [confNewPassword,setConfNewPassword] = useState('')
    const [stage,setStage] = useState(1)
    const [loading,setLoading] = useState(false)
    const [statusMsg,setStatusMsg] = useState('')
    const [status, setStatus]:any = useState('');

    function changePassword(){
        setLoading(true)
        const formData = {
            currentPassword:currPassword,
            newPassword:newPassword,
            confirmPassword:confNewPassword
        }
        axios.post(`${apiUrl}/change-password`,formData,{
            headers:headers,
            withCredentials:true
        }).then(res => {
            if (res.data.status === 'error'){
                setStatus('fail')
                setStatusMsg(res.data.message)
                setLoading(false)
            }
            if (res.data.status === 'success'){
                setStatusMsg(res.data.message+'. Logging you out...')
                setStatus('success')
                logout()
            }
        }).catch(err => {
            console.log(err)
            setStatusMsg(err.message)
            setStatus('fail')
            setLoading(false)
        })
    }

    return (
        <DashboardLayout pageSlug="change-password" pageTitle="Change Password">
            {stage === 1 &&
                <form onSubmit={(e)=>{
                    e.preventDefault()
                    setStatusMsg('')
                    setStatus('')
                    if (!currPassword || !newPassword || !confNewPassword) {
                        setStatusMsg('All fields are required')
                    }else if(newPassword !== confNewPassword){
                        setStatusMsg('New password and confirmation don\'t match')
                    }else if(newPassword.length < 8){
                        setStatusMsg('Password must be at least 8 characters long')
                    } else{
                        setStage(2)
                    }
                }}>
                    <div className="flex flex-col gap-[16px] mt-[32px]">
                        {statusMsg && <StatusMessage message={statusMsg} status={status}/>}
                        <TextField type="password" placeholder="Current Password" value={currPassword} onChange={(e)=>{setCurrPassword(e.target.value)}}/>
                        <TextField type="password" placeholder="New Password" value={newPassword} onChange={(e)=>{setNewPassword(e.target.value)}}/>
                        <TextField type="password" placeholder="Confirm Password" value={confNewPassword} onChange={(e)=>{setConfNewPassword(e.target.value)}}/>
                        <Button variant="primary" type="submit">Continue</Button>
                    </div>
                </form>
            }
            {stage === 2 &&
                <>
                    <div className="mb-[16px] mt-[32px]">
                        <Heading4 text="Confirm Password Change?"/>
                        <p className="text-red text-sm"><i className="fa-duotone fa-triangle-exclamation"/> You shall be logged out and you will need to log in again with the new password.</p>
                    </div>
                    <div className="flex gap-[16px] mt-[16px]">
                        <Button variant="secondary" type="button" onClick={()=>{setStage(1)}} disabled={loading}>Cancel</Button>
                        <Button variant="primary" type="button" onClick={changePassword} disabled={loading} autoFocus={true}>{loading ? <LoadingSpinner/> : 'Confirm'}</Button>
                    </div>
                    <div className="mt-[32px]">
                        {statusMsg && <StatusMessage message={statusMsg} status={status}/>}
                    </div>
                </>
            }
        </DashboardLayout>
    )
}

export default ChangePassword