import {useEffect} from "react";
import {Link} from "react-router-dom";
import {logout} from "../helpers";

interface ProfilePanelProps{
    closePanel: (a:boolean)=>void,
    userName:string
}

const ProfilePanel = (props:ProfilePanelProps) => {
    const {closePanel,userName} = props

    useEffect(()=>{
        (document.querySelector('body') as HTMLElement).classList.add('overflow-hidden')
    },[])

    return (
        <div className="absolute bottom-0 left-0 right-0 top-0 p-[16px] bg-white">
            <button type="button" className="bg-white rounded-full w-[40px] h-[40px] text-primary-blue flex justify-center items-center mb-[8px]" onClick={()=>{
                (document.querySelector('body') as HTMLElement).classList.remove('overflow-hidden')
                closePanel(false)
            }}><i className="fa-solid fa-xmark fa-2x"/></button>
            <div className="mb-[8px]">
                <h3 className="text-3xl font-bold grotesk">{userName}</h3>
            </div>
            <hr/>
            <div className="flex flex-col gap-[16px] mt-[16px]">
                <Link to="/dashboard/change-password" className="text-xl"><i className="fa-solid fa-lock mr-[8px]"/> Change Password</Link>
                <button type="button" className="text-xl text-left" onClick={logout}><i className="fa-solid fa-right-from-bracket mr-[8px]"/> Logout</button>
            </div>

            {/*footer note*/}
            <div className="absolute bottom-0 mb-[8px] right-0 left-0">
                <p className="text-center">Designed and Developed with ❤️ by KraftKey</p>
            </div>
        </div>
    )
}

export default ProfilePanel