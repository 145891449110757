import {useParams} from "react-router";
    import logo from '../assets/imgs/iworkout-gym-w-bg.png'
import React, {useState} from "react";
import TextField from "../components/TextField";
import StatusMessage from "../components/StatusMessage";
import Button from "../components/Button";
import {apiUrl} from "../helpers";
import axios from "axios";
import LoadingSpinner from "../components/LoadingSpinner";

const ActivateRep = () => {
    const [stage,setStage] = useState(1)
    const [respMsg,setRespMsg] = useState('')
    const [errMsg,setErrMsg] = useState('')
    const [username,setUsername] = useState('')
    const [password,setPassword] = useState('')
    const [confPassword,setConfPassword] = useState('')
    const [loading,setLoading] = useState(false)
    const params = useParams()
    const {repId} = params

    function activateAccount(){
        setErrMsg('')
        setRespMsg('')
        if (!username || !password || !confPassword) return setErrMsg('All fields are required')
        if (password !== confPassword) return setErrMsg('Password and confirm do not match')
        setLoading(true)

        axios.post(`${apiUrl}/reps/activate-rep`,{
            username:username,
            password:password,
            confirmPass:confPassword,
            repId:repId
        },{
            headers:{
                'Content-Type':'application/json',
                'Accept':'application/json',
                'withCredentials':true
            }
        })
            .then(res => {
                if (res.data.status === 'success') setStage(stage + 1)
                if (res.data.status === 'error') setErrMsg(res.data.message)
                setLoading(false)
            })
            .catch(err => {
                setErrMsg(err.message)
                console.log(err)
                setLoading(false)
            })
    }

    return (
        <>
            <div className="mx-[16px]">
                <img src={logo} className="w-[150px] mx-auto pt-[10%] rounded-[16px]" alt="logo" width={150} height={150}/>
                <p className="text-center mb-[16px] text-xl font-bold">Thank you for joining iWorkout Gym.</p>
                {stage === 1 &&
                    <>
                        <p>To get you up and running, you should activate your account. Choose a username and password then activate your account.</p>
                        <form onSubmit={(e)=>{
                            e.preventDefault()
                            activateAccount()
                        }}>
                            <div className="flex flex-col gap-[8px] pt-[32px] rounded-[8px]">
                                {respMsg && <StatusMessage message={respMsg} status="success"/>}
                                {errMsg && <StatusMessage message={errMsg} status="fail"/>}
                                <TextField type="text" value={username} onChange={(e)=>{setUsername(e.target.value)}} placeholder="Username"/>
                                <TextField type="password" value={password} onChange={(e)=>{setPassword(e.target.value)}} placeholder="Password"/>
                                <TextField type="password" value={confPassword} onChange={(e)=>{setConfPassword(e.target.value)}} placeholder="Confirm Password"/>
                                <Button type="submit" variant="primary" disabled={loading}>{loading ? <LoadingSpinner/> : 'Activate Account'}</Button>
                            </div>
                        </form>
                    </>
                }
                {stage === 2 &&
                    <>
                        <div className="flex flex-col gap-[16px] items-center mt-[54px]">
                            <div>
                                <i className="fa-duotone fa-circle-check fa-6x text-primary-green"/>
                            </div>
                            <p className="grotesk font-bold">Account activated successfully</p>
                        </div>
                        <div className="absolute bottom-0 left-0 right-0 p-[16px]">
                            <Button variant="primary" type="button" onClick={()=>{window.location.replace('/')}}>Continue to Login</Button>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default ActivateRep