interface Heading4Props {
    text:string
}

const Heading4 = (props:Heading4Props) => {
    const {text} = props
    return (
        <h4 className="grotesk text-2xl font-bold text-primary-blue mb-[16px] text-center">{text}</h4>
    )
}

export default Heading4