import { useEffect, useState} from "react";
import axios from "axios";
import {apiUrl, headers, membershipDays} from "../helpers";
import Button from "./Button";
import LoadingSpinner from "./LoadingSpinner";
import {QrCodeScanner} from "react-simple-qr-code-scanner";

interface ScanMemberProps{
    scans:(a:any[]) => void,
}
const ScanMember = (props:ScanMemberProps) => {
    const {scans} = props
    const [showScanner,setShowScanner] = useState(false)
    const [memberInfo,setMemberInfo]:any = useState({})
    const [loading,setLoading] = useState(false)

    function getScans(){
        axios.get(`${apiUrl}/reps/member-scans`,{
            headers:headers,
            withCredentials:true
        }).then(res => {
            if (res.data && res.data.length > 0) scans(res.data)
            if (res.data && res.data.length === 0) scans(['nothing'])
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(getScans,[])

    function scanMember(memberId:string){
        setLoading(true)
        const formData = {
            memberId:memberId
        }
        axios.post(`${apiUrl}/reps/scan-member`,formData,{
            headers:headers,
            withCredentials:true
        }).then(res => {
            setMemberInfo(res.data)
            getScans()
            setLoading(false)
        })
    }

    return (
        <>
            {showScanner &&
                <>
                    <div className="bg-white mt-[16px] rounded-[8px] h-[300px] relative flex justify-center items-center overflow-hidden">
                        <p className="absolute z-0">Loading camera...</p>
                        <div className="z-10">
                            <QrCodeScanner onResult={(val:any)=>{
                                scanMember(val.getText())
                                setShowScanner(false)
                            }} Errors={(err:any)=>{console.log(err)}} facingMode="environment"/>
                        </div>
                        <button className="absolute z-10 top-0 right-0 p-[8px] text-white rounded-[8px] bg-red/80 w-[30px] h-[30px] flex justify-center items-center my-[4px] mx-[4px]" type="button" onClick={()=>{setShowScanner(false)}}><i className="fa-solid fa-xmark"/></button>
                    </div>
                    <p className="text-center">Point the camera at the member's QR code to scan</p>
                </>
            }
            {!showScanner &&
                <>
                    {Object.keys(memberInfo).length === 0 &&
                        <div className="bg-white mt-[16px] p-[16px] rounded-[8px] h-[300px] relative flex justify-center items-center">
                            {loading &&
                                <>
                                    <LoadingSpinner/>&nbsp;&nbsp;Verifying member info...
                                </>
                            }
                            {!loading &&
                                <div>
                                    <Button variant="primary" textSize="text-lg" type="button" onClick={()=>{setShowScanner(true)}}><i className="fa-duotone fa-qrcode"/> Scan Member</Button>
                                </div>
                            }
                        </div>
                    }
                    {Object.keys(memberInfo).length > 0 &&
                        <>
                            {memberInfo.status && memberInfo.status === 'error' &&
                                <div className="bg-white mt-[16px] p-[16px] rounded-[8px] h-auto">
                                    <p className="text-red text-center mb-[8px] grotesk font-bold text-[24px]">{memberInfo.message}</p>
                                    <p>This could be due to one of the following factors:</p>
                                    <ul className="my-[16px] mx-[16px] list-disc">
                                        <li>The member's account may have been deleted/deactivated due to non payment</li>
                                        <li>The member's account just does not exist</li>
                                    </ul>
                                    <p>If there's any confusion about the status of this member's membership, contact management.</p>
                                    <div className="flex justify-between mt-[32px] gap-[16px]">
                                        <Button variant="primary" type="button" onClick={()=>{setMemberInfo({})}}>Reset</Button>
                                        <Button variant="secondary" type="button" onClick={()=>{setShowScanner(true)}}>Scan Another</Button>
                                    </div>
                                </div>
                            }
                            {!memberInfo.status &&
                                <div className="bg-white mt-[16px] p-[16px] rounded-[8px] h-auto">
                                    <div>
                                        <p>Name: <span className="text-xl font-bold grotesk text-primary-blue">{memberInfo.name.toUpperCase()}</span></p>
                                        <p className="mb-[16px]">Membership Days Remaining: <span className="text-xl font-bold grotesk text-primary-green">{membershipDays(memberInfo.remaining_days)}</span></p>
                                        <hr/>
                                        <p className="mt-[16px]">Gender: <span className="text-xl font-bold grotesk text-primary-blue">{memberInfo.gender.toUpperCase()}</span></p>
                                        <p>Phone: <span className="text-xl font-bold grotesk text-primary-blue">{memberInfo.phone}</span></p>
                                        <p>Is Active: <span className="text-xl font-bold grotesk text-primary-blue">{memberInfo.is_active ? 'ACTIVE' : 'INACTIVE'}</span></p>
                                    </div>
                                    <div className="flex justify-between mt-[32px] gap-[16px]">
                                        <Button variant="primary" type="button" onClick={()=>{setMemberInfo({})}}>Reset</Button>
                                        <Button variant="secondary" type="button" onClick={()=>{setShowScanner(true)}}>Scan Another</Button>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </>
            }
        </>
    )
}

export default ScanMember
