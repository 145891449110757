import TextField from "../components/TextField";
import {useState} from "react";
import Button from "../components/Button";
import Heading4 from "../components/Heading4";
import logo from '../assets/imgs/iworkout-gym-w-bg.png'
import axios from "axios";
import {apiUrl, headers} from "../helpers";
import StatusMessage from "../components/StatusMessage";
import LoadingSpinner from "../components/LoadingSpinner";

function Login() {
    const [username,setUsername] = useState('')
    const [password,setPassword] = useState('')
    const [respMsg,setRespMsg] = useState('')
    const [loading,setLoading] = useState(false)

    function processLogin(){
        setRespMsg('')

        if (!username || !password){
            setRespMsg('All fields are required')
            return
        }

        setLoading(true)
        const formData = {
            username:username,
            password:password
        }

        axios.post(`${apiUrl}/login`,formData,{
            headers:headers,
            withCredentials:true
        }).then(res => {
            if (res.data.status === 'success') window.location.replace('/dashboard')
            setRespMsg(res.data.message)
        }).catch(err => {
            setRespMsg(err.message)
            console.log(err)
            setLoading(false)
        })
    }

  return (
    <main className="w-full md:w-1/2 px-[32px] md:mx-auto my-[32px]">
        <form className="flex flex-col gap-[16px] items-center" onSubmit={(e)=>{
            e.preventDefault()
            processLogin()
        }}>
            <div>
                <img src={logo} alt="Logo"/>
            </div>
            <Heading4 text="Welcome Back"/>
            {respMsg && <StatusMessage message={respMsg} status="fail"/>}
            <TextField type="text" withbg={true} placeholder="Username" required={true} onChange={(e)=>{setUsername(e.target.value)}} value={username} autoFocus={true}/>
            <TextField type="password" withbg={true} placeholder="Password" required={true} onChange={(e)=>{setPassword(e.target.value)}} value={password}/>
            <Button type="submit" variant="primary" textSize="text-xl" disabled={loading}>{loading ? <LoadingSpinner/> : 'Login'}</Button>
        </form>
    </main>
  )
}

export default Login
