import React from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>{
    variant:'primary'|'secondary',
    textSize?:'text-lg'|'text-xl'|'text-2xl'
    children:React.ReactNode,
}

const Button = (props:ButtonProps) => {
    const {variant,children,textSize} = props
    return (
        <button className={`p-[16px] w-full rounded-[8px] font-bold disabled:cursor-not-allowed disabled:bg-white/70 disabled:text-secondary-blue/80 ${textSize} ${variant === "primary" ? 'bg-primary-blue text-white' : 'bg-secondary-green text-primary-blue'}`} {...props}>{children}</button>
    )
}

export default Button