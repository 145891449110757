import React, {useContext, useEffect, useState} from "react";
import {AnimatePresence,motion} from "framer-motion";
import AnimatedLoader from "../components/AnimatedLoader";
import {UserProfileContext} from "../stores/userProfileContext";
import {Link} from "react-router-dom";
import ProfilePanel from "./ProfilePanel";

interface DashboardLayoutProps{
    children:React.ReactNode,
    pageTitle?:string,
    pageSlug:string,
    userProfile?:(a:object)=>void

}
const DashboardLayout = (props:DashboardLayoutProps) => {
    const {children,pageSlug,pageTitle,userProfile} = props
    const [profilePanel,setProfilePanel] = useState(false)
    const profile:any = useContext(UserProfileContext)
    // const weather:never = useContext(WeatherContext)
    const [userInitials,setUserInitials] = useState('')

    useEffect(()=>{
        if (Object.keys(profile).length > 0){
            if (!profile.name){
                window.location.replace('/')
            }else{
                if (userProfile) {
                    userProfile(profile)
                }
                let init = ''
                const split = profile.name.split(' ')
                for (let n=0; n < split.length; n++){
                    init += split[n].charAt(0)
                    if (n == 1) break
                }
                setUserInitials(init)
            }
        }
    },[profile,userProfile])

    return (
        <>
            {Object.keys(profile).length === 0 &&
                <div className="flex justify-center items-center absolute inset-0">
                    <div className="flex flex-col gap-[16px] items-center text-center p-[16px]">
                        <AnimatedLoader/>
                    </div>
                </div>
            }
            {Object.keys(profile).length > 0 &&
                <>
                    <div className="p-[16px] mb-[56px]">
                        {/*weather area*/}
                        <div className="flex justify-between items-center">
                            <h2 className="text-3xl font-bold grotesk">{pageTitle}</h2>
                            <button type="button" className="rounded-full w-[50px] h-[50px] border-[1px] flex justify-center items-center" onClick={()=>{setProfilePanel(true)}}>
                                <span className="font-bold text-2xl grotesk">{userInitials}</span>
                            </button>
                        </div>
                        {children}
                    </div>
                    <div className="fixed bottom-0 left-0 right-0 p-[8px] text-primary-blue flex justify-around items-center bg-white">
                        <AnimatePresence>
                            {pageSlug === 'home' ?
                                <motion.div
                                    animate={{
                                        scale:1
                                    }}
                                    initial={{
                                        scale:0.8
                                    }}
                                    transition={{
                                        duration:20,
                                        type:'spring',
                                        stiffness:200
                                    }}
                                    className="flex gap-[8px] items-center justify-between bg-primary-blue text-white py-[8px] px-[16px] rounded-full text-[14px]">
                                    <i className="fa-duotone fa-grid-2"/> Overview
                                </motion.div>
                                :
                                <Link to="/dashboard" className="mx-[32px] focus-visible:border-none"><i className="fa-duotone fa-grid-2 fa-2x"/></Link>
                            }
                        </AnimatePresence>
                        {pageSlug === 'membership' ?
                            <motion.div
                                animate={{
                                    scale:1
                                }}
                                initial={{
                                    scale:0.8
                                }}
                                exit={{
                                    scale:0.8
                                }}
                                transition={{
                                    duration:20,
                                        type:'spring',
                                    stiffness:200
                                }}
                                className="flex gap-[8px] items-center justify-between bg-primary-blue text-white py-[8px] px-[16px] rounded-full text-[14px]">
                                <i className="fa-duotone fa-address-card"/> Register Member
                            </motion.div>
                            :
                            <Link to="/dashboard/register-member" className="mx-[32px] focus-visible:border-none"><i className="fa-duotone fa-address-card fa-2x"/></Link>
                        }
                    </div>

                    {/*profile panel*/}
                    {profilePanel && <ProfilePanel closePanel={(a:boolean)=>{setProfilePanel(a)}} userName={profile.name}/>}
                </>
            }
        </>
    )
}

export default DashboardLayout