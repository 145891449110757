import axios from "axios";

export const apiUrl = process.env.REACT_APP_API_URL
export const headers = {
    'Accept':'application/json',
    'Content-Type':'application/json'
}

export function membershipDays(expirationDate:string){
    const dayjs = require('dayjs')
    return dayjs(expirationDate).diff(new Date(),'day')+1
}

export function logout(){
    axios.post(`${apiUrl}/logout`,{},{
        headers:headers,
        withCredentials:true
    }).then(res => {
        if (res.data.status === 'success'){
            window.location.replace('/')
        }
    }).catch(err => {
        console.log(err)
    })
}