import React from "react";

interface TextFieldProps extends React.InputHTMLAttributes<HTMLInputElement>{
    label?:string,
    withbg?:boolean
}

const TextField = (props:TextFieldProps) => {
    const {label,withbg} = props
    return (
        <div className="flex flex-col gap-[8px] w-full">
            {label && <label>{label}</label>}
            <input className={`w-full rounded-[8px] border-primary-blue border-[1px] text-primary-blue p-[16px] focus-visible:outline-primary-green/30 disabled:cursor-not-allowed disabled:text-secondary-blue ${withbg ? 'bg-white' : ''}`} {...props}/>
        </div>
    )
}

export default TextField