import Button from "../components/Button";
import errorImg from '../assets/imgs/error.jpg'
import {useNavigate} from "react-router-dom";

const Unauthorised = () => {
    const navigate = useNavigate()
    function takeMeHome(){
        navigate('/')
    }

    return (
        <div className="flex flex-col gap-[16px] justify-center items-center h-screen w-full">
            <img src={errorImg} alt="error 401" width={350}/>
            <div>
                <h4 className="text-center text-3xl font-bold">UNAUTHORISED</h4>
                <p>You are not authorised to view this page</p>
            </div>
            <div>
                <p className="mb-[16px]">If I am not redirected automatically ...</p>
                <Button variant="primary" type="button" onClick={takeMeHome}>Take Me Home</Button>
            </div>
        </div>
    )
}

export default Unauthorised