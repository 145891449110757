import DashboardLayout from "../components/DashboardLayout";
import ScanMember from "../components/ScanMember";
import {useState} from "react";
import Heading4 from "../components/Heading4";
import {membershipDays} from "../helpers";

const Dashboard = () => {
    const [scans,setScans] = useState([])
    const dayjs = require('dayjs')

    return (
        <DashboardLayout pageSlug="home" pageTitle="Overview">
            <ScanMember scans={(a)=>{setScans(a as any)}}/>
            <div className="mt-[32px]">
                <Heading4 text="Members Scanned Today"/>
                {scans.length === 0 &&
                    <div className="flex flex-col flex-1 items-center">
                        Loading your scanned members...
                    </div>
                }
                {scans.length > 0 &&
                    <>
                    {scans[0] === 'nothing' &&
                        <div className="p-[16px] rounded-[8px] text-center mt-[32px]">
                            <h4 className="grotesk text-[20px] font-bold text-primary-blue/50 text-center">It's a clean slate</h4>
                            <p className="text-primary-blue/50 text-[14px]">Members you have scanned today shall be listed here</p>
                        </div>
                    }
                    {scans[0] !== 'nothing' && scans.map((scan:any,index:number) => (
                        <div key={index} className="bg-white p-[16px] rounded-[8px]">
                            <h4 className="text-xl font-bold text-primary-blue grotesk">{scan.member.name}</h4>
                            <div className="flex justify-between">
                                <div className="flex gap-[16px] mt-[8px]">
                                    <p className="bg-secondary-blue/50 px-[8px] py-[4px] text-primary-blue rounded-full">{membershipDays(scan.member.membership_expiration)} {membershipDays(scan.member.membership_expiration) === 1 ? 'DAY' : 'DAYS'}</p>
                                    <p className="bg-secondary-blue/50 px-[8px] py-[4px] text-primary-blue rounded-full">{scan.member.gender.toUpperCase()}</p>
                                    <p className="bg-secondary-blue/50 px-[8px] py-[4px] text-primary-blue rounded-full">{scan.member.is_active ? 'ACTIVE' : 'INACTIVE'}</p>
                                </div>
                                <div>
                                    <p>{dayjs(scan.scanned_on).format('h:mm a')}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                    </>
                }
            </div>
        </DashboardLayout>
    )
}

export default Dashboard