import DashboardLayout from "../components/DashboardLayout";
import React, {useState} from "react";
import TextField from "../components/TextField";
import Button from "../components/Button";
import StatusMessage from "../components/StatusMessage";
import axios from "axios";
import {apiUrl, headers} from "../helpers";
import LoadingSpinner from "../components/LoadingSpinner";
import {useNavigate} from "react-router-dom";

const RegisterMember = () => {
    const [name,setName] = useState('')
    const [gender,setGender] = useState('')
    const [email,setEmail] = useState('')
    const [phone,setPhone] = useState('')
    const [errMsg,setErrMsg] = useState('')
    const [loading,setLoading] = useState(false)
    const [stage,setStage] = useState(1)
    const navigate = useNavigate()

    function addMember(){
        setErrMsg('')
        if (!name || !gender || !email || !phone){
            setErrMsg('All fields are mandatory')
            return
        }

        setLoading(true)

        const formData = {
            name:name,
            gender:gender,
            email:email,
            phone:phone
        }

        axios.post(`${apiUrl}/members`,formData,{
            headers:headers,
            withCredentials:true
        }).then(res => {
            if (res.data.status === 'error'){
                setErrMsg(res.data.message)
            }
            if (res.data.status === 'success'){
                setStage(stage + 1)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            console.log(err)
            if(err.response) setErrMsg(err.response.data.message)
            if(!err.response) setErrMsg(err.message)
        })
    }

    return (
        <DashboardLayout pageSlug="membership" pageTitle="Register Member">
            {stage === 1 &&
                <>
                    <p className="mt-[8px]">Use the form below to register a new member on to the platform</p>
                    <form className="mt-[16px]" onSubmit={(e)=>{
                        e.preventDefault()
                        addMember()
                    }}>
                        <div className="mb-[8px]">
                            {errMsg && <StatusMessage message={errMsg} status="fail"/>}
                        </div>
                        <div className="flex flex-col gap-[8px]">
                            <TextField type="text" value={name} onChange={(e)=>{setName(e.target.value)}} required={true} placeholder="Name"/>
                            <div className="flex gap-[16px] my-[8px]">
                                <p>Gender:</p>
                                <label>
                                    <input type="radio" name="gender" value="male" onChange={(e)=>{setGender(e.target.value)}} required={true}/> Male
                                </label>
                                <label>
                                    <input type="radio" name="gender" value="female" onChange={(e)=>{setGender(e.target.value)}} required={true}/> Female
                                </label>
                            </div>
                            <TextField type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} required={true} placeholder="Email"/>
                            <TextField type="text" value={phone} onChange={(e)=>{
                                if (isNaN(Number(e.target.value))) return
                                setPhone(e.target.value)
                            }} required={true} placeholder="Phone number"/>
                        </div>
                        <div className="mt-[16px]">
                            <p className="text-red/70 text-sm">NB: Be sure to enter a valid email and phone number. If the email or phone number are incorrect, registrant may not receive their activation message.</p>
                        </div>
                        <div className="mt-[16px]">
                            <Button variant="primary" type="submit" disabled={loading}>{loading ? <LoadingSpinner/> : 'Submit'}</Button>
                        </div>
                    </form>
                </>
            }
            {stage === 2 &&
                <>
                    <div className="text-center my-[32px]">
                        <i className="fa-duotone fa-circle-check fa-5x text-primary-green"/>
                        <div className="mt-[16px]">
                            <p className="text-[24px] font-bold grotesk mb-[16px] text-primary-green">{name}<br/> was added successfully.</p>
                            <p>An activation email has been sent to their email at <b className="text-red/50">{email}</b> and to their phone number at <b className="text-red/50">{phone}</b>.</p>
                        </div>
                    </div>
                    <div className="flex gap-[16px]">
                        <Button variant="primary" type="button" onClick={()=>{navigate('/dashboard')}}>Done</Button>
                        <Button variant="secondary" type="button" onClick={()=>{
                            setName('')
                            setEmail('')
                            setGender('')
                            setPhone('')
                            setStage(stage - 1)
                        }}>Add Another</Button>
                    </div>
                </>
            }
        </DashboardLayout>
    )
}

export default RegisterMember