import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './styles/app.css'
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Unauthorised from "./pages/unauthorised";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import UserProfileContextProvider from "./stores/userProfileContext";
import ActivateRep from "./pages/activate-rep";
import RegisterMember from "./pages/register-member";
import ChangePassword from "./pages/change-password";

const router = createBrowserRouter([
    {
        path: "*",
        element: <Unauthorised/>,
    },
    {
        path: "/",
        element: <Login/>,
    },
    {
        path: "/dashboard",
        element: <Dashboard/>,
    },
    {
        path: "/dashboard/register-member",
        element: <RegisterMember/>,
    },
    {
        path: "/dashboard/change-password",
        element: <ChangePassword/>,
    },
    {
        path: "/activate/:repId",
        element: <ActivateRep/>,
    },
    {
        path: "/not-authorised",
        element: <Unauthorised/>,
    }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
    <React.StrictMode>
    <UserProfileContextProvider>
          <RouterProvider router={router}/>
        </UserProfileContextProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
